import gql from "graphql-tag";

export const GET_CURRENT_TENANT = gql`
  query currentTenant {
    currentTenant {
      externalId
      statuses
      id
      logoUrl
      name
      ordersEmail
      ordersEmailName
    }
  }
`;
