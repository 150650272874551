import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { ALL_RFQS } from "modules/RFQ/graphql/queries";
import { useDeleteRfqMutation } from "interfaces/graphql";
import { useDialog } from "context/DialogContext/DialogContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "context/SnackbarContext/SnackbarContext";

interface IDeleteRFQConfirmationDialogProps {
  rfqId: string;
}

const DeleteRFQConfirmationDialog = ({
  rfqId,
}: IDeleteRFQConfirmationDialogProps) => {
  const { showMessage } = useSnackbar();
  const { closeDialog } = useDialog();
  const navigate = useNavigate();

  const [deleteRfq, { data, loading, error }] = useDeleteRfqMutation({
    refetchQueries: [{ query: ALL_RFQS }],
  });

  const onDeleteClick = () => {
    deleteRfq({
      variables: {
        id: rfqId,
      },
    });
  };

  useEffect(() => {
    if (data) {
      navigate("/rfqs");
      showMessage("RFQ removed successfully!", "success");
      closeDialog();
    }
  }, [data]);

  return (
    <>
      <DialogTitle>Are you sure you want to delete #{rfqId} RFQ?</DialogTitle>
      <DialogContent>#{rfqId} will be deleted permanently.</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            closeDialog();
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClick();
          }}
          variant="outlined"
        >
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteRFQConfirmationDialog;
