import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { IVendorRfqTableData } from "modules/VendorRfqResponse/interfaces";
import { VendorRfqResponseActions } from "modules/VendorRfqResponse/reducer/VendorRfqResponseReducer";
import { useSnackbar } from "context/SnackbarContext/SnackbarContext";
import { useState } from "react";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";

interface IVendorQuoteRemoveRFQItemProps {
  item: IVendorRfqTableData;
}

const VendorQuoteRemoveRFQItem = ({ item }: IVendorQuoteRemoveRFQItemProps) => {
  const { showMessage } = useSnackbar();
  const { state, dispatch } = useVendorRfqResponse();
  const [openDialog, setOpenDialog] = useState(false);

  const onAccept = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const vendorItems = state.vendorItems.filter(
      (i) => i.rfqItemId !== item.id
    );

    dispatch({
      type: VendorRfqResponseActions.setVendorItems,
      payload: vendorItems,
    });

    setOpenDialog(false);
    showMessage("Item removed from RFQ", "success");
  };

  const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenDialog(false);
  };

  const onDialogContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setOpenDialog(true);
        }}
      >
        <ClearIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        open={openDialog}
        onClose={onClose}
        aria-labelledby="remove-item-dialog"
        onClick={(e) => onDialogContentClick(e)}
      >
        <DialogTitle>Remove item</DialogTitle>
        <DialogContent>
          Are you sure you want to remove <b>{item?.name}</b> from the RFQ?
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onAccept} variant="outlined">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VendorQuoteRemoveRFQItem;
