import gql from "graphql-tag";

export const GET_FOLDERS = gql`
  query folders($filter: FolderFilter) {
    folders(filter: $filter) {
      id
      # [BUG] Returns 500 from server when trying to fetch children
      children {
        ... on Folder {
          id
          name
          imageUrl
          parentId
        }
      }

      #   ... on ItemSummary {
      #     id
      #     grade
      #     imageUrl
      #     minLevel
      #     name
      #     parentId
      #     pricePerUnit
      #     quantity
      #   }
      # }
      name
      imageUrl
      parentId
    }
  }
`;

export const GET_FOLDERS_DROPDOWN = gql`
  query foldersDropdown($filter: FolderFilter) {
    folders(filter: $filter) {
      id
      name
      # children
      imageUrl
    }
  }
`;

export const GET_ITEMS_SUMMARY = gql`
  query itemsSummary($filter: ItemFilter) {
    itemsSummary(filter: $filter) {
      grade
      id
      imageUrl
      minLevel
      name
      parentId
      pricePerUnit
      isArchived
      quantity
    }
  }
`;

export const GET_STOCK_ITEM = gql`
  query item($id: ID!) {
    item(id: $id) {
      activities {
        ... on ItemArchiveActivity {
          createdAt
          id
          isArchived
          itemId
          reason
          user
        }
        ... on ItemGradedActivity {
          createdAt
          grade
          id
          itemId
          reason
          user
        }
        ... on ItemNeedsAttentionActivity {
          createdAt
          id
          itemId
          reason
          user
        }
        ... on ItemNeedsNoAttentionActivity {
          createdAt
          id
          itemId
          reason
          user
        }
        ... on ItemQuantityUpdatedActivity {
          createdAt
          id
          itemId
          newQuantity
          oldQuantity
          orderId
          reason
          user
          vendor {
            contactEmail
            contactName
            hidden
            id
            name
            note
            website
          }
          vendorId
        }
        ... on ItemTakenActivity {
          createdAt
          id
          itemId
          quantity
          toWhere
          user
        }
        ... on ItemMovedToLocationActivity {
          createdAt
          fromLocation
          user
          toLocation
          quantityMoved
          movedItemExpiry
        }
      }
      addedToOrders
      addedToRfqs
      barcode
      becameLowStockAt
      description
      grade
      id
      imageUrl
      isArchived
      isLowStock
      manufacturer
      manufacturerId
      minLevel
      name
      needsAttention
      note
      parentId
      pricePerUnit
      quantities {
        value
        expiresAt
        location {
          id
          name
          isDefault
        }
      }
    }
  }
`;

export const SEARCH_STOCK_ITEMS = gql`
  query searchStockItems($filter: ItemFilter) {
    itemsSummary(filter: $filter) {
      grade
      id
      imageUrl
      isArchived
      isLowStock
      minLevel
      name
      parentId
      pricePerUnit
      quantity
    }
  }
`;

export const GET_ITEMS_AND_FOLDERS_FOR_SEARCH = gql`
  query getItemsAndFoldersForSearch($parentId: ID) {
    navigateStock(parentId: $parentId) {
      ... on Folder {
        id
        parentId
        imageUrl
        name
      }
      ... on ItemSummary {
        grade
        id
        imageUrl
        isArchived
        minLevel
        name
        parentId
        pricePerUnit
        quantity
      }
    }
  }
`;

export const NAVIGATE_STOCK = gql`
  query navigateStock($parentId: ID) {
    navigateStock(parentId: $parentId) {
      ... on Folder {
        id
        parentId
        imageUrl
        name
      }
      ... on ItemSummary {
        grade
        id
        imageUrl
        minLevel
        name
        parentId
        pricePerUnit
        quantity
        isArchived
      }
    }
  }
`;

export const ALERTS_SUBSCRIPTION = gql`
  subscription itemAlert {
    itemAlert {
      id
      name
      activities {
        __typename
        ... on ItemGradedActivity {
          id
          createdAt
          user
          grade
          reason
        }
        ... on ItemTakenActivity {
          id
          createdAt
          quantity
          user
          toWhere
        }
      }
      quantities {
        value
        expiresAt
        location {
          id
          name
          isDefault
        }
      }
      minLevel
      manufacturerId
      manufacturer
    }
  }
`;

export const LOW_STOCK = gql`
  query lowStock {
    lowStock {
      becameLowStockAt
      orderIds
      rfqIds
      stockId
      stockName
    }
  }
`;

export const GET_ITEM_LOCATIONS = gql`
  query itemLocations {
    itemLocations {
      id
      isDefault
      name
    }
  }
`;

export const GET_STOCK_AUDIT_SUMMARIES = gql`
  query stockAuditSummaries {
    stockAuditSummaries {
      id
      status
      createdAt
      createdBy
    }
  }
`;

export const GET_STOCK_AUDIT = gql`
  query stockAudit($id: ID!) {
    stockAudit(id: $id) {
      createdAt
      createdBy
      id
      items {
        expiresAt
        id
        imageUrl
        locationId
        quantityCounted
        quantityExpected
        status
        stockItemId
        stockItemName
      }
      status
    }
  }
`;

export const SEARCH_ITEMS_FOR_AUDIT = gql`
  query searchItemsForAudit($filter: SearchItemsForAuditFilter!) {
    searchItemsForAudit(filter: $filter) {
      stockItemId
      name
      imageUrl
      locationId
      quantity
      expiresAt
    }
  }
`;
