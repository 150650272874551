import "./NavList.css";

import { ListSubheader, useTheme } from "@mui/material";
import hasPermission, { Action } from "modules/User/utils/checkUserRole";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BarChartIcon from "@mui/icons-material/BarChart";
import FolderIcon from "@mui/icons-material/Folder";
import List from "@mui/material/List";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NavListItem from "components/NavListItem/NavListItem";
import NavSignOut from "components/NavSignOut/NavSignOut";
import PieChartIcon from "@mui/icons-material/PieChart";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SettingsIcon from "@mui/icons-material/Settings";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import WarningIcon from "@mui/icons-material/Warning";
import { useLocation } from "react-router-dom";
import useUserDetails from "modules/User/hooks/useUserDetails";
import { useViewPermissions } from "hooks/useViewPermissions/useViewPermissions";

interface INavListProps {
  open: boolean;
  onClose?: () => void;
}

const NavList = ({ open, onClose }: INavListProps) => {
  const viewPermissions = useViewPermissions();
  const userDetails = useUserDetails();
  const location = useLocation();
  const theme = useTheme();

  const isDeveloper = hasPermission(
    userDetails.roles,
    Action.CanViewDevSettings
  );

  const pathname = location.pathname;
  const {
    canViewDashboard,
    canViewPurchaseOrders,
    canViewRFQs,
    canViewStock,
    canViewVendors,
    canViewReports,
    canViewAppSettings,
  } = viewPermissions;

  // Define the nav properties for each section with more precise path matching
  const navProps = {
    dashboard: {
      to: "/dashboard",
      open,
      onClose,
      icon: (
        <PieChartIcon
          color={
            pathname === "/dashboard" || pathname === "/"
              ? "primary"
              : "inherit"
          }
        />
      ),
      text: "Dashboard",
      selected: pathname === "/dashboard" || pathname === "/",
    },
    purchaseOrders: {
      to: "/orders",
      open,
      onClose,
      icon: (
        <LocalShippingIcon
          color={pathname === "/orders" ? "primary" : "inherit"}
        />
      ),
      text: "Purchase Orders",
      selected: pathname === "/orders",
    },
    rfqs: {
      to: "/rfqs",
      open,
      onClose,
      icon: (
        <RequestQuoteIcon
          color={pathname === "/rfqs" ? "primary" : "inherit"}
        />
      ),
      text: "RFQs",
      tooltipText: "Request for Quotation",
      tooltipEnabled: true,
      selected: pathname === "/rfqs",
    },
    stock: {
      to: "/stock",
      open,
      onClose,
      icon: (
        <FolderIcon color={pathname === "/stock" ? "primary" : "inherit"} />
      ),
      text: "Stock",
      selected: pathname === "/stock",
    },
    lowStock: {
      to: "/low-stock",
      open,
      onClose,
      icon: (
        <WarningIcon
          color={pathname === "/low-stock" ? "primary" : "inherit"}
        />
      ),
      text: "Low Stock",
      selected: pathname === "/low-stock",
    },
    stockAudit: {
      to: "/stock-audits",
      open,
      onClose,
      icon: (
        <AccountTreeIcon
          color={pathname === "/stock-audits" ? "primary" : "inherit"}
        />
      ),
      text: "Stock Audit",
      selected: pathname === "/stock-audits",
    },
    vendors: {
      to: "/vendors",
      open,
      onClose,
      icon: (
        <LocalShippingIcon
          color={pathname === "/vendors" ? "primary" : "inherit"}
        />
      ),
      text: "Vendors",
      selected: pathname === "/vendors",
    },
    user: {
      to: "/user",
      open,
      onClose,
      icon: (
        <ManageAccountsIcon
          color={pathname === "/user" ? "primary" : "inherit"}
        />
      ),
      text: "User",
      selected: pathname === "/user",
    },
    reportsStock: {
      to: "/reports-stock",
      open,
      onClose,
      icon: (
        <BarChartIcon
          color={pathname === "/reports-stock" ? "primary" : "inherit"}
        />
      ),
      text: "Stock Reports",
      selected: pathname === "/reports-stock",
    },
    reportsOrders: {
      to: "/reports-orders",
      open,
      onClose,
      icon: (
        <SignalCellularAltIcon
          color={pathname === "/reports-orders" ? "primary" : "inherit"}
        />
      ),
      text: "Orders Reports",
      selected: pathname === "/reports-orders",
    },
    appSettings: {
      to: "/app-settings",
      open,
      onClose,
      icon: (
        <SettingsIcon
          color={pathname === "/app-settings" ? "primary" : "inherit"}
        />
      ),
      text: "App Settings",
      selected: pathname === "/app-settings",
    },
  };

  return (
    <>
      {!open ? (
        <List
          sx={{ mt: "-.5rem" }}
          component="nav"
          aria-labelledby="nested-navigation-list"
        >
          {canViewDashboard && <NavListItem {...navProps.dashboard} />}
          {canViewPurchaseOrders && (
            <NavListItem {...navProps.purchaseOrders} />
          )}
          {canViewRFQs && <NavListItem {...navProps.rfqs} />}
          {canViewStock && <NavListItem {...navProps.stock} />}
          {canViewStock && <NavListItem {...navProps.lowStock} />}
          {canViewStock && <NavListItem {...navProps.stockAudit} />}
          {canViewVendors && <NavListItem {...navProps.vendors} />}
          <NavListItem {...navProps.user} />
          <NavSignOut open={open} />
        </List>
      ) : (
        <>
          <List
            component="nav"
            aria-labelledby="nested-navigation-list"
            className="unselectable"
            subheader={
              open ? (
                <ListSubheader
                  component="div"
                  style={{
                    color: pathname.startsWith("/dashboard")
                      ? theme.palette.primary.main
                      : "",
                    fontWeight: pathname.startsWith("/dashboard") ? "bold" : "",
                  }}
                >
                  {canViewDashboard ? "GENERAL" : null}
                </ListSubheader>
              ) : null
            }
          >
            {canViewDashboard && <NavListItem {...navProps.dashboard} />}
          </List>

          <List
            component="nav"
            aria-labelledby="nested-navigation-list"
            className="unselectable"
            subheader={
              open ? (
                <ListSubheader
                  component="div"
                  style={{
                    color:
                      pathname === "/orders" ||
                      pathname === "/rfqs" ||
                      pathname === "/stock" ||
                      pathname === "/low-stock" ||
                      pathname === "/stock-audits" ||
                      pathname === "/vendors"
                        ? theme.palette.primary.main
                        : "",
                    fontWeight:
                      pathname === "/orders" ||
                      pathname === "/rfqs" ||
                      pathname === "/stock" ||
                      pathname === "/low-stock" ||
                      pathname === "/stock-audits" ||
                      pathname === "/vendors"
                        ? "bold"
                        : "",
                  }}
                >
                  {canViewPurchaseOrders ||
                  canViewRFQs ||
                  canViewStock ||
                  canViewVendors
                    ? "ORDER MANAGEMENT"
                    : null}
                </ListSubheader>
              ) : null
            }
          >
            {canViewPurchaseOrders && (
              <NavListItem {...navProps.purchaseOrders} />
            )}
            {canViewRFQs && <NavListItem {...navProps.rfqs} />}
            {canViewStock && <NavListItem {...navProps.stock} />}
            {canViewStock && <NavListItem {...navProps.lowStock} />}
            {canViewStock && <NavListItem {...navProps.stockAudit} />}
            {canViewVendors && <NavListItem {...navProps.vendors} />}
          </List>

          {canViewReports && (
            <List
              component="nav"
              aria-labelledby="nested-general-list"
              className="unselectable"
              subheader={
                open ? (
                  <ListSubheader
                    component="div"
                    style={{
                      color: pathname.startsWith("/reports")
                        ? theme.palette.primary.main
                        : "",
                      fontWeight: pathname.startsWith("/reports") ? "bold" : "",
                    }}
                  >
                    REPORTS
                  </ListSubheader>
                ) : null
              }
            >
              <NavListItem {...navProps.reportsStock} />
              <NavListItem {...navProps.reportsOrders} />
            </List>
          )}

          <List
            component="nav"
            aria-labelledby="nested-general-list"
            className="unselectable"
            subheader={
              open ? (
                <ListSubheader
                  component="div"
                  style={{
                    color:
                      pathname === "/user" || pathname === "/app-settings"
                        ? theme.palette.primary.main
                        : "",
                    fontWeight:
                      pathname === "/user" || pathname === "/app-settings"
                        ? "bold"
                        : "",
                  }}
                >
                  SETTINGS
                </ListSubheader>
              ) : null
            }
          >
            {canViewAppSettings && <NavListItem {...navProps.appSettings} />}
            <NavListItem {...navProps.user} />

            <NavSignOut open={open} />
          </List>
        </>
      )}
    </>
  );
};

export default NavList;
