import * as Yup from "yup";

import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { GET_VENDORS } from "modules/Vendors/graphql/queries";
import { Item } from "components/Form/ItemStyles/Item";
import Loader from "components/Loader/Loader";
import { useCreateVendorMutation } from "interfaces/graphql";
import { useDialog } from "context/DialogContext/DialogContext";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "context/SnackbarContext/SnackbarContext";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormValues {
  name: string;
  contactEmail: string;
  contactName: string;
  website?: string | null;
  note?: string | null;
}

const CreateVendorDialog = () => {
  const { showMessage } = useSnackbar();
  const { closeDialog } = useDialog();

  const [createVendor, { data, loading, error }] = useCreateVendorMutation({
    refetchQueries: [{ query: GET_VENDORS }],
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").max(200),
    contactName: Yup.string().required("Contact name is required").max(100),
    contactEmail: Yup.string().email("Invalid email").max(100),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (data) {
      showMessage("Vendor created successfully!", "success");
      closeDialog();
      reset();
    }
  }, [data, reset]);

  const onSubmit = (data: FormValues) => {
    createVendor({
      variables: {
        input: {
          name: data.name,
          contactEmail: data.contactEmail,
          contactName: data.contactName,
          website: data.website,
          note: data.note,
        },
      },
    });
  };

  const handleClose = () => {
    closeDialog();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create Vendor</DialogTitle>
        {error && (
          <Alert severity="error" sx={{ mb: "1rem" }}>
            There is a network problem. Please refresh the page or try again
            later.
          </Alert>
        )}
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12}>
                <Item>
                  <TextField
                    {...register("name")}
                    required
                    fullWidth
                    spellCheck
                    label="Company name"
                    id="name"
                    name="name"
                    size="small"
                    error={Boolean(errors.name)}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      Boolean(errors.name) ? errors.name?.message : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        position: "absolute",
                        top: "3.4rem",
                      },
                    }}
                  />
                </Item>
              </Grid>
            </Grid>
            <Typography variant="overline">Contact details</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextField
                    {...register("contactName")}
                    required
                    spellCheck
                    fullWidth
                    id="contactName"
                    name="contactName"
                    label="Salesperson"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.name)}
                    helperText={
                      Boolean(errors.name) ? errors.name?.message : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        position: "absolute",
                        top: "3.4rem",
                      },
                    }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextField
                    {...register("contactEmail")}
                    fullWidth
                    id="contactEmail"
                    name="contactEmail"
                    label="Email"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.contactEmail)}
                    helperText={
                      Boolean(errors.contactEmail)
                        ? errors.contactEmail?.message
                        : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        position: "absolute",
                        top: "3.4rem",
                      },
                    }}
                  />
                </Item>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Item>
                  <TextField
                    {...register("website")}
                    label="Website"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Item>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Item>
                  <TextField
                    {...register("note")}
                    label="Note"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Item>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: ".5rem" }}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
              <Button variant="outlined" type="submit" disabled={loading}>
                Add
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </>
  );
};

export default CreateVendorDialog;
