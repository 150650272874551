import AllItemsAndFoldersSearchInput from "components/AllItemsAndFoldersSearchInput/AllItemsAndFoldersSearchInput";
import { AllStockProvider } from "modules/Stock/context/AllStockContext";
import { Box } from "@mui/material";

const StockToolbar = () => {
  return (
    <AllStockProvider>
      <Box
        sx={{
          width: "50%",
          border: "1px solid #e0e0e0",
        }}
      >
        <AllItemsAndFoldersSearchInput />
      </Box>
    </AllStockProvider>
  );
};

export default StockToolbar;

// import SearchIcon from "@mui/icons-material/Search";
// import StockSearchDialog from "modules/Stock/components/StockSearchDialog/StockSearchDialog";
// import { useDialog } from "context/DialogContext/DialogContext";

// const Search = styled("div")(({ theme }) => ({
//   border: `1px solid ${theme.palette.grey[400]}`, // Subtle border for contrast
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.black, 0.025), // Slightly gray background
//   "&:hover": {
//     cursor: "pointer",
//     backgroundColor: alpha(theme.palette.common.black, 0.1), // Darker background on hover
//   },
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: theme.palette.text.primary, // Match text with theme
//   width: "100%",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`, // Space for icon
//     fontSize: "1rem",
//   },
// }));

// const { openDialog } = useDialog();

// const handleSearchBarClick = () => {
// sx={{
//   width: { xs: "90%", sm: "500px" },
//   height: { xs: "auto", sm: "400px" },
// }}
// openDialog(<StockSearchDialog />, { fullWidth: true, maxWidth: "sm" });
// };

// <Grid container justifyContent="center">
//   <Grid item xs={4} onClick={handleSearchBarClick}>
//     <Search>
//       <SearchIconWrapper>
//         <SearchIcon color="action" />
//       </SearchIconWrapper>
//       <StyledInputBase
//         placeholder="Search…"
//         inputProps={{ "aria-label": "search" }}
//       />
//     </Search>
//   </Grid>
// </Grid>
