import { GET_CURRENT_TENANT } from "modules/User/graphql/quries";
import { GET_STOCK_AUDIT_SUMMARIES } from "../grahpql/queries";
import { useCreateStockAuditMutation } from "interfaces/graphql";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "context/SnackbarContext/SnackbarContext";

export const useCreateStockAudit = () => {
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();

  const [createStockAudit, { data, loading, error }] =
    useCreateStockAuditMutation({
      refetchQueries: [
        {
          query: GET_STOCK_AUDIT_SUMMARIES,
        },
        {
          query: GET_CURRENT_TENANT,
        },
      ],
    });

  useEffect(() => {
    if (data) {
      navigate(`/stock-audits/${data.createStockAudit.id}`);
      showMessage("Audit created successfully!", "success");
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      showMessage(
        "We apologize for the inconvenience. Please try again later or contact our support team for further assistance.",
        "error"
      );
    }
  }, [error]);

  const onCreateClick = () => {
    createStockAudit();
  };

  return { onCreateClick, loading, data };
};
