import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search"; // Import Material-UI Search Icon
import { set } from "date-fns";
import useDebounce from "utils/useDebounce";
import { useNavigate } from "react-router-dom";
import { useSearchStockLazyQuery } from "interfaces/graphql";

interface SearchableItem {
  id: string;
  name: string;
}

const SearchStock = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(""); // For user input
  const [options, setOptions] = useState<SearchableItem[]>([]); // For search results
  const [searchMade, setSearchMade] = useState(false); // Flag to track if a search has been executed
  const debouncedInput = useDebounce(inputValue, 300); // Debounce user input

  const [fetchStock, { data, loading, error }] = useSearchStockLazyQuery();

  useEffect(() => {
    if (debouncedInput.trim()) {
      setSearchMade(true); // Mark search as executed
      fetchStock({
        variables: { nameTerm: debouncedInput },
      });
    } else {
      setOptions([]);
      setSearchMade(false); // Reset the search state
    }
  }, [debouncedInput, fetchStock]);

  useEffect(() => {
    if (data?.searchStock) {
      setOptions(data.searchStock); // Update options with the fetched data
    }
  }, [data]);

  const handleOptionSelect = (
    _event: React.SyntheticEvent,
    value: string | SearchableItem | null
  ) => {
    if (typeof value === "object" && value !== null) {
      setInputValue(""); // Clear the input field
      navigate(`/stock/item/${value.id}`);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Search submitted:", inputValue);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Autocomplete
          freeSolo
          options={options}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name || ""
          }
          isOptionEqualToValue={(option, value) =>
            option.id === value?.id ||
            option.name
              .toLowerCase()
              .includes(value?.toString().toLowerCase() || "")
          }
          filterOptions={(x) => x} // No filtering
          onInputChange={(_event, value) => setInputValue(value)}
          onChange={handleOptionSelect}
          loading={loading}
          renderInput={(params) => (
            <Box sx={{ position: "relative" }}>
              <TextField
                {...params}
                fullWidth
                size="small"
                label="Search Stock"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
              {!loading && searchMade && options.length === 0 && (
                <Box
                  sx={{
                    mt: 1,
                    position: "absolute",
                    width: "100%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    padding: "8px",
                    zIndex: 1,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body1" color="text.secondary">
                    No results found for your search.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        />
      </form>

      {error && (
        <Box sx={{ mt: 2, color: "red", textAlign: "center" }}>
          <Typography variant="body1">
            Failed to fetch results. Please try again later.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SearchStock;
