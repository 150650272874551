import { Box, Button, Grid } from "@mui/material";

import ImageDisplay from "components/ImageContainer/ImageDisplay/ImageDisplay";
import ImageNewUploader from "components/ImageContainer/ImageNewUploader/ImageNewUploader";
import fileIcon from "assets/illustrations/file.jpg";
import folderIcon from "assets/illustrations/folder-icon.svg";
import { useState } from "react";

interface ICreateImageProps {
  setFileDataURL: React.Dispatch<React.SetStateAction<string | null>>;
  isFolder?: boolean;
  image?: string | null | undefined;
  onSave?: () => void;
  loading?: boolean;
  folderId?: string;
}

const CreateImage = ({
  setFileDataURL,
  isFolder,
  image,
  onSave,
  loading,
  folderId,
}: ICreateImageProps) => {
  const [imageUrl, setImageUrl] = useState<string | null | undefined>(image);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const defaultIcon = isFolder ? folderIcon : fileIcon;

  const onFileUploadSuccess = (file: Blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64DataURL = reader.result as string;

      // The result includes the Data URL which can be used to display the image and is sent to the parent
      setImageUrl(base64DataURL);

      // Need to strip begining of the string, which includes the file type
      setFileDataURL(base64DataURL.split(",")[1]);
      setIsFileUploaded(true);
    };
    reader.readAsDataURL(file);
  };

  const onCancel = () => {
    setImageUrl(image);
    setFileDataURL(null);
    setIsFileUploaded(false);
  };

  return (
    <Grid container gap={1}>
      <ImageDisplay imageUrl={imageUrl} defaultIcon={defaultIcon} />
      <ImageNewUploader
        isFileUploaded={isFileUploaded}
        onFileUploadSuccess={onFileUploadSuccess}
        folderOnEdit={true}
        folderId={folderId}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        {isFileUploaded && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={onCancel}
          >
            Undo
          </Button>
        )}

        {isFileUploaded && onSave && (
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={onSave}
            disabled={loading}
          >
            Update
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export default CreateImage;
