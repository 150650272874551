// import AllItemsAndFoldersSearchInput from "components/AllItemsAndFoldersSearchInput/AllItemsAndFoldersSearchInput";
// import { AllStockProvider } from "modules/Stock/context/AllStockContext";
// import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import SearchStock from "modules/Stock/components/SearchStock/SearchStock";

const StockToolbar = () => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <SearchStock />
      </Grid>
    </Grid>
    // <AllStockProvider>
    // <Box
    //   sx={{
    //     width: "50%",
    //     border: "1px solid #e0e0e0",
    //   }}
    // >

    //   <AllItemsAndFoldersSearchInput />
    // </Box>
    // </AllStockProvider>
  );
};

export default StockToolbar;
