import * as yup from "yup";

import {
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import LottieLoader from "components/LottieLoader/LottieLoader";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import confetti from "canvas-confetti";
import logo from "assets/logo/reacting_logo.svg";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

type FormData = {
  practiceName: string;
  practiceManagerName: string;
  practiceEmailAddress: string;
  managersEmailAddress: string;
  recaptcha: string;
};

const schema = yup.object().shape({
  practiceName: yup.string().required("Practice name is required"),
  practiceManagerName: yup
    .string()
    .required("Practice manager name is required"),
  practiceEmailAddress: yup
    .string()
    .email("Invalid email")
    .required("Practice email address is required"),
  managersEmailAddress: yup
    .string()
    .email("Invalid email")
    .required("Manager's email address is required"),
  recaptcha: yup.string().required("Please complete the CAPTCHA"),
});

const DemoRegisterPage = () => {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const triggerConfetti = () => {
    confetti({
      particleCount: 500,
      startVelocity: 50,
      spread: 360,
      origin: {
        y: 0.2,
      },
    });
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(true);
    if (!recaptchaToken) {
      console.error("CAPTCHA not completed");
      return;
    }

    const url = `${process.env.REACT_APP_ONBOARD_NEW_USER_URL}`;
    const payload = {
      practiceName: data.practiceName,
      practiceManagerName: data.practiceManagerName,
      practiceEmailAddress: data.practiceEmailAddress,
      managersEmailAddress: data.managersEmailAddress,
    };

    try {
      const response = await axios.post(url, payload);

      if (response.status === 201) {
        triggerConfetti();
        setFormData(data);
        setIsSuccess(true);
        console.log("API Response:", response.data);
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      alert("Something went wrong. Please try again.");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    setValue("recaptcha", token || "");
  };

  if (loading) {
    return <LottieLoader />;
  }

  if (isSuccess) {
    return (
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="Default Logo"
              width="200"
              style={{ maxWidth: "100%", marginBottom: "16px" }}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{
                textAlign: "center",
                marginBottom: "16px",
              }}
            >
              Thank you!
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                textAlign: "center",
                marginBottom: "8px",
              }}
            >
              An invitation has been sent to:
            </Typography>
            <Typography gutterBottom>
              <strong>{formData?.managersEmailAddress}</strong>
            </Typography>
            <Typography gutterBottom>to join the organization:</Typography>

            <Typography gutterBottom>
              <strong>{formData?.practiceName}</strong>
            </Typography>
            <Typography
              variant="body1"
              style={{ textAlign: "center", marginTop: 16 }}
            >
              Please check your email to accept the invitation.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Default Logo"
            width="200"
            style={{ maxWidth: "100%", marginBottom: "16px" }}
          />
          <Typography
            variant="h4"
            gutterBottom
            style={{ whiteSpace: "nowrap" }}
          >
            Register for Reacting account
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h6" gutterBottom>
              Practice Information
            </Typography>
            <Controller
              name="practiceName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  label="Practice Name"
                  error={!!errors.practiceName}
                  helperText={
                    errors.practiceName
                      ? String(errors.practiceName.message)
                      : ""
                  }
                  margin="dense"
                />
              )}
            />
            <Controller
              name="practiceEmailAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  label="Practice Email Address"
                  error={!!errors.practiceEmailAddress}
                  helperText={
                    errors.practiceEmailAddress
                      ? String(errors.practiceEmailAddress.message)
                      : ""
                  }
                  margin="dense"
                />
              )}
            />
            <Divider style={{ margin: "24px 0" }} />
            <Typography variant="h6" gutterBottom>
              Manager Information
            </Typography>
            <Controller
              name="practiceManagerName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  label="Practice Manager Name"
                  error={!!errors.practiceManagerName}
                  helperText={
                    errors.practiceManagerName
                      ? String(errors.practiceManagerName.message)
                      : ""
                  }
                  margin="dense"
                />
              )}
            />
            <Controller
              name="managersEmailAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  label="Manager's Email Address"
                  error={!!errors.managersEmailAddress}
                  helperText={
                    errors.managersEmailAddress
                      ? String(errors.managersEmailAddress.message)
                      : ""
                  }
                  margin="dense"
                />
              )}
            />
            <Controller
              name="recaptcha"
              control={control}
              defaultValue=""
              render={() => (
                <>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                    onChange={handleRecaptchaChange}
                  />
                  {errors.recaptcha && (
                    <Typography color="error">
                      {errors.recaptcha.message}
                    </Typography>
                  )}
                </>
              )}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "16px" }}
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DemoRegisterPage;
