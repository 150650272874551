import Lottie from "lottie-react";
import loadingAnimation from "assets/animations/LoadingAnimation.json";

const LottieLoader = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <Lottie
      animationData={loadingAnimation}
      loop
      style={{ width: 150, height: 150 }}
    />
    <p style={{ color: "#555", fontSize: "1.5rem", marginTop: "1rem" }}>
      Setting everything up...
    </p>
  </div>
);

export default LottieLoader;
