import "./styles/index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from "./components/App/App";
import { Auth0ProviderWithNavigate } from "routes/Auth0ProviderWithNavigate";
import AuthApolloProvider from "./clients/auth-apollo-graphql";
import { BrowserRouter } from "react-router-dom";
import { InstallPromptProvider } from "context/InstallPromptContext/InstallPromptContext";
import React from "react";
import { SnackbarProvider } from "context/SnackbarContext/SnackbarContext";
import { createRoot } from "react-dom/client";

const RootComponent = () => {
  console.log("Root Component", process.env.REACT_APP_ENVIRONMENT);
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <AuthApolloProvider>
            <SnackbarProvider>
              <InstallPromptProvider>
                <App />
              </InstallPromptProvider>
            </SnackbarProvider>
          </AuthApolloProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);
root.render(<RootComponent />);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      registration.addEventListener("updatefound", () => {
        window.location.reload();
      });
    }
  },
});

navigator.serviceWorker.addEventListener("message", (event) => {
  if (event?.data === "newSW") {
    console.log(
      "New service worker is active. Reloading the page for the latest version."
    );
    window.location.reload();
  }
});
